import React, { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab'
import hash from 'object-hash'
import { Container } from '@material-ui/core'
import { Button } from '../button/button'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'

export const StyledAlert = styled(Alert)<{ $type?: AlertNoticeProps['type']; display: boolean }>`
  display: ${props => props.display ? 'flex' : 'none'};
  border-radius: unset;
  align-items: center;

  .MuiAlert-icon {
    color: #ed8b00;
    align-items: center;
  }

  .MuiAlert-root {
    padding: ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(29)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(32)};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(57)} 0 ${props => props.theme.typography.pxToRem(58)};
    }
  }

  .MuiAlert-message {
    font-size: ${props => props.theme.typography.pxToRem(15)};
    line-height: ${props => props.theme.typography.pxToRem(24)};
    padding-bottom: 5px;
    padding-top: 3px;

    ${props => props.theme.breakpoints.up('md')} {
      padding-top: 8px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
  }

  a:hover {
    color: ${props => props.theme.palette.secondary.dark};
  }

  background: none;
  ${props => props.$type === 'dark' && 'color: white;'}
`

const StyledContainer = styled(Container)<{$type?: AlertNoticeProps['type']}>`
  ${props => props.theme.breakpoints.down('sm') && 'padding: unset;'}

  ${props => props.$type === 'primary' && css`
  color: #1c1f2a;
  background-color: #f2f2f2;
  `}

  ${props => props.$type === 'light' && css`
  color: #1c1f2a;
  background-color: #fff7e6;
  `}

  ${props => props.$type === 'dark' && css`
  color: white;
  background-color: #10131d;
  `}
`

const StyledButton = styled(Button)<{$color?: string}>`
  font-size: 12px;
  color: white;

  &:hover {
    color: white;
  }
`

const StyledReadMoreorLessButton = styled(Button)`
  display: block;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.secondary.main};
  font-weight: 100;
  padding-left: 0;
  padding-top: 0;
  text-align: left;
  padding-bottom: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }
`

const StyledAlertTitle = styled(AlertTitle)`
  font-weight: 500;
  color: #0D3C61;
  margin-bottom: 0;
  font-size: ${props => props.theme.typography.pxToRem(14)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(15)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

const StyledAlertTitleDesktop = styled(AlertTitle)`
  font-weight: 500;
  color: #0D3C61;
  margin-bottom: 0;
  font-size: ${props => props.theme.typography.pxToRem(15)};
`

const StyledAlertContent = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(15)};
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  margin-bottom: ${props => props.theme.typography.pxToRem(5)};

  ${props => props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

const StyledCookieAlertContent = styled.div`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  line-height: 1rem;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export type AlertNoticeProps = AlertProps & {
  type?: 'light' | 'dark' | 'primary'
  hashInfo: string
  buttonText?: string
  mobileTitle?: string
  readMore: boolean
  cookieAlert: boolean
};

export const GlobalAlert: React.FC<AlertNoticeProps> = ({ children, type, buttonText, hashInfo, mobileTitle, readMore, cookieAlert, ...props }) => {
  const theme = useTheme()
  const [displayState, setDisplay] = useState(false)
  const [open, setOpen] = React.useState(false)


  useEffect(() => {
    const hashedChild = hash(hashInfo)

    if (localStorage.getItem(hashedChild) !== 'hidden') {
      setDisplay(true)
    }
  }, [hashInfo])

  const setDisplayStorage = useCallback((unhashedChild) => {
    localStorage.setItem(hash(unhashedChild), 'hidden')
    setDisplay(false)
  }, [])

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = () => {
    setOpen((prev) => !prev)
  }

  return (
    <StyledContainer $type={type} maxWidth={false}>
      <StyledContainer maxWidth="lg">
        {
          isMobile ? (
            <StyledAlert display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity="info" $type={type} 
              action={
                buttonText ? (
                  <StyledButton onClick={()=> setDisplayStorage(hashInfo)} $color={type} primary buttonColor="orange">
                    {buttonText}
                  </StyledButton>) : null
              }
              { ...props }>
              <StyledAlertTitle>{mobileTitle}</StyledAlertTitle>
              { readMore && !open &&
                <StyledReadMoreorLessButton text onClick={()=> handleChange()}>Read more</StyledReadMoreorLessButton>
              }
              { cookieAlert ? (
                <StyledCookieAlertContent>
                  { children }
                </StyledCookieAlertContent>
              ) : (
                <Collapse in={open}>
                  <StyledAlertContent> 
                    {children}
                  </StyledAlertContent>
                  { readMore && open &&
                <StyledReadMoreorLessButton text onClick={()=> handleChange()}>Read less</StyledReadMoreorLessButton>
                  }
                </Collapse>
              )}
            </StyledAlert>
          ) : (
            <StyledAlert display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity="info" $type={type} 
              action={
                buttonText ? (
                  <StyledButton onClick={()=> setDisplayStorage(hashInfo)} $color={type} primary buttonColor="orange">
                    {buttonText}
                  </StyledButton>) : null
              }
              { ...props }>
              <StyledAlertTitleDesktop>{mobileTitle}</StyledAlertTitleDesktop>
              {children}
            </StyledAlert>
          )
        }
      </StyledContainer>
    </StyledContainer>
  )
}
